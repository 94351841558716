<template>
  <div>
    <el-row style="width: 1200px; margin: 0 auto" :gutter="24">
      <el-col :span="5">
        <!-- <div class="doc"> -->
        <!-- <doctors-list :doctors="doctors" class="doc"></doctors-list> -->
        <el-row>
          <!-- <el-col :span="25" v-for="project in detail" :key="project.pid"> -->
          <el-col :span="25">
            <doctors-list-itemtest
              :doctors-item="detail"
              class="now"
            ></doctors-list-itemtest>
          </el-col>
        </el-row>
      </el-col>
      <el-col :span="13">
        <el-row>
          <div class="vt1">{{ detail.infoTitle }}</div>
          <div class="vttime">{{ startTime }}</div>
          <div class="playvideo">
            <video-player
              class="video-player vjs-custom-skin"
              ref="videoPlayer"
              :playsinline="true"
              :options="videoPlayerOptions"
              v-loading="loading"
            ></video-player>
          </div>
          <div class="vname">视频内容</div>
          <!-- <span>{{detail.content}}</span> -->
          <!-- <span class="vcon" v-for="(item, index) in detail.content" :key="index">
          {{item}}</span> -->
          <div class="vcon">
            <el-row>
              <el-col :span="24" v-for="(item, index) in msg" :key="index">
                {{ item }}
              </el-col>
            </el-row>
            <el-row style="margin-top:30px;">
              <el-col>
      <div class="_xw2rajjf48q"></div>
              </el-col>
            </el-row>
          </div>
        </el-row>
      </el-col>
      <el-col :span="6" style="border: 1px solid #EAEDF0">
        <!-- <div style="position:relative"> -->
        <el-row>
          <el-col><div class="cvideo">
            <img src="~assets/connectvideo.svg" alt="">
            </div></el-col>
          <el-col
            v-for="(item, index) in relatevideos"
            :key="index"
            class="videosize"
          >
            <small-videos-list-itemtest
              :videos-item="item"
              class="sv1"
            ></small-videos-list-itemtest>
          </el-col>
        </el-row>
        <el-row class="lrow">
          <el-col>
            <span class="cdoc">
              <img src="~assets/docw.svg" alt="">
            </span>
          </el-col>
          <el-col
            v-for="(item, index) in videos"
            :key="index"
            class="videosize"
          >
            <div v-if="index < 4">
              <small-videos-list-itemtest
                :videosItem="item"
                class="sv2"
              ></small-videos-list-itemtest>
            </div>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
    <div class="mianbox">
      <div class="mian">
        <recommend-title
          :Title="'相关推荐'"
          :eTitle="'VOICE ANSWER'"
          :color="'#4263EB'"
          class="rectitle"
        ></recommend-title>
        <el-row
          style="margin-top:80px; width:950px"
          v-if="relaterecommended.length != 0"
        >
          <el-col
            :span="24"
            v-for="project in relaterecommended"
            :key="project.pid"
            :offset="1"
            class="elhover"
          >
            <long-videos-listtest
              :videos="project"
              class="now ql"
              :totop="true"
            ></long-videos-listtest>
          </el-col>
        </el-row>
        <el-empty
          v-else
          description="描述文字"
          style="margin-top:100px; width: 100px;
    text-align: center;"
        ></el-empty>
      </div>
    </div>
  </div>
</template>
<script>
import LongVideosListtest from 'components/content/videos/LongVideosListtest'
import SmallVideosListItemtest from 'components/content/videos/SmallVideosListItemtest'
// import DoctorsList from 'components/content/doctors/DoctorsList'
import DoctorsListItemtest from 'components/content/doctors/DoctorsListItemtest'
import RecommendTitle from 'components/content/recommendtitle/RecommendTitle'
// 视频播放
import { videoPlayer } from 'vue-video-player'
// import { Decrypt } from '@/util/EncryptionToDecrypt'
import { getMediadetailList } from 'network/getData'
import formateDate from 'util/formateDate'
import subString from 'util/changeString'
// import SmallVideosListItemtest from '../../components/content/videos/SmallVideosListItemtest.vue'
export default {
  name: 'VideoDetail',
  components: {
    // DoctorsList,
    RecommendTitle,
    videoPlayer,
    DoctorsListItemtest,
    SmallVideosListItemtest,
    LongVideosListtest
  },
  data () {
    return {
      fileType: 'mkv',
      doctors: [],
      relatevideos: [],
      videos: [],
      detail: [],
      startTime: '',
      msg: [],
      loading: true,
      relaterecommended: [],
      title: '视频',
      content: ''
    }
  },
  watch: {
    $route: function (newVal) {
      // this.getMediadetailListdata(this.$route.query.videoId, 'video')
      // const id = sessionStorage.getItem('id')
      // this.getMediadetailListdata(id, 'video')
    }
  },
  methods: {
    async getMediadetailListdata (infoId, type) {
      try {
        const result = await getMediadetailList(infoId, type)
        this.videos = result.data.data
        this.detail = result.data.data.detail
        this.msg = subString(this.detail.content)
        this.videos = result.data.data.doctorWorks
        this.relatevideos = result.data.data.relatedContent
        this.relaterecommended = result.data.data.relatedScienceContent
        // const d = new Date(this.detail.startTime)
        const d = this.detail.startTime ? new Date(this.detail.startTime) : ''
        this.startTime = formateDate(d)
        this.loading = false
        this.title = this.detail.infoTitle
        this.content = this.msg[0].length > 100 ? this.msg[0].slice(0, 100) : this.msg[0]
      } catch (error) {
        // console.log(error)
      }
    }
  },
  // metaInfo () {
  //   return {
  //     title: this.title + '_瑞尔安心',
  //     meta: [
  //       { name: 'keywords', content: this.title },
  //       { name: 'description', content: this.content }
  //     ]
  //   }
  // },
  created () {
    // console.log(this.$route.query.i, '11111111111111111111111')
    // const id = sessionStorage.getItem('id')
    this.getMediadetailListdata(this.$route.query.videoId, 'video')
  },
  mounted () {
    (window.slotbydup = window.slotbydup || []).push({
      id: 'u6745378',
      container: '_xw2rajjf48q',
      async: true
    })
  },
  computed: {
    videoPlayerOptions () {
      const videoPlayerOptions = {
        playbackRates: [0.75, 1.0, 1.25, 1.5, 2.0], // 播放速度
        autoplay: false, // 是否自动播放。
        muted: false, // 是否静音播放，默认情况下将会消除任何音频。
        loop: false, // 是否循环播放。
        preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: 'zh-CN',
        aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 是否流体从而按比例缩放以适应其容器。
        flash: { hls: { withCreadentials: false } }, // 可以播放rtmp视频
        html5: { hls: { withCreadentials: false } }, // 可以播放m3u8视频
        sources: [{
          // type: 'video/' + this.fileType,
          type: '',
          // src: this.videoUrl // 视频url地址
          // src: require('../../assets/music/aqa.mp4')
          src: this.detail.infoUrl
        }],
        // poster: this.posterUrl, // 封面地址
        poster: '',
        width: '100%',
        notSupportedMessage: this.loading ? '加载中...' : '此视频暂无法播放...', // 当无法播放时允许覆盖Video.js，显示的默认信息。
        controlBar: {
          timeDivider: true,
          durationDisplay: true,
          remainingTimeDisplay: false,
          fullscreenToggle: true
        }
      }
      return videoPlayerOptions
    }
  }
}
</script>

<style scoped>
.tbox{
  position: relative;
  width: 1200px;
  /* height: 751px; */
  /* left: 120px; */
  /* margin-left: 120px; */
  /* top: 100px; */
  /* background: #FAFAFA; */
}
.doc{
  /* position: absolute; */
  width: 232px;
  height: 415px;
  left: 20px;
  top: 20px;
}
.lbox{
  /* position: absolute; */
  width: 302px;
  height: 645px;
  left: 898px;
  top: 26px;
  border: 1px solid #EAEDF0;
  box-sizing: border-box;
}
.cvideo, .cdoc{
  /* position: absolute; */
  /* width: 96px;
  height: 28px;
  left: 23px;
  top: 15px; */
  font-family: "PangMenZhengDao-3";
  font-size: 24px;
  line-height: 28px;
  color: #262626;
  margin-left: 15px;
  margin-top: 10px;
}
.sv1{
  /* position: absolute; */
  width: 269px;
  height: 52px;
  left: 12px;
  /* top: 61px; */
}
.sv2{
  /* position: absolute; */
  width: 269px;
  height: 52px;
  left: 12px;
  /* top: 315px; */
}
.vt1{
  /* position: absolute; */
  /* width: 336px; */
  height: 38px;
  left: 272px;
  top: 20px;
  text-align: left;
  font-size: 28px;
  line-height: 38px;
  /* text-align: center; */
  color: #000000;
}
.vttime{
  /* position: absolute; */
  width: 174px;
  height: 24px;
  left: 272px;
  top: 68px;
  color: #8C8C8C;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
}
.vname{
  /* position: absolute; */
  width: 580px;
  height: 28px;
  margin-top: 20px;
  font-size: 20px;
  line-height: 28px;
  color: #262626;
}

.vcon{
/* position: absolute; */
width: 620px;
/* height: 168px; */
left: 272px;
top: 503px;
font-size: 16px;
line-height: 24px;
color: #262626;
}
.vcon .el-col{
  margin-top: 10px;
}
.playvideo{
  /* position: absolute; */
  width: 602px;
  height: 339px;
  background: #E8E8E8;
  border: 1px solid #E8E8E8;
}
.mianbox{
  /* position: absolute; */
width: 1200px;
/* height: 1610px; */
/* left: 120px; */
/* top: 901px; */
margin: 0 auto;
margin-top: 80px;
}
.mian{
  display: flex;
flex-direction: column;
align-items: center;
padding: 0px;
/* position: absolute; */
margin-bottom: 200px;
/* width: 1200px; */
/* height: 1534px; */
/* left: 125px; */
top: 0px;
}
.videosize{
  margin-top: 10px;
}
.lrow{
    margin-top: 21px;
  /* margin-bottom: 24px; */
}
.guanggao{
    width:620px;
  margin-bottom: 20px;
}
</style>
